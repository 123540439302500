<template src="./ChangesInDPSChart.html"></template>

<script>
import { Chart } from 'highcharts-vue';

/*
 * 個別銘柄配当ページの年間1株配当額推移チャートコンポーネント
 *
 * @vue-prop {Hash} changesInDps
 * @module ChangesInDpsChart
 */

export default {
  name: 'ChangesInDPSChart',
  props: {
    changesInDps: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    highcharts: Chart,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: '160px',
          style: {
            fontFamily:
              "-apple-system,'BlinkMacSystemFont','Hiragino Kaku Gothic ProN','Noto Sans CJK JP','Noto Sans Japanese',sans-serif",
          },
        },
        title: {
          text: '',
        },
        xAxis: {
          title: {
            text: '',
          },
          offset: 1,
          lineWidth: 2,
          lineColor: '#808080',
          categories: this.changesInDps['fiscal_periods'].map((period, idx) =>
            idx === this.changesInDps['fiscal_periods'].length - 1 ? `${period}<br>(予想)` : period
          ),
          labels: {
            autoRotationLimit: 40,
            style: {
              fontSize: 10,
            },
          },
          crosshair: {
            color: 'none',
          },
        },
        yAxis: {
          title: {
            text: '（円）',
            rotation: 0,
            align: 'low',
            offset: 0,
            y: 20,
            x: -30,
            style: {
              fontSize: 10,
            },
          },
          labels: {
            style: {
              fontSize: 10,
            },
          },
          min: 0,
          tickPositioner: function () {
            const dataAbsMax = Math.max(Math.abs(this.dataMin), Math.abs(this.dataMax));
            const ceil = (n) => ((m) => m * Math.ceil(n / m))(10 ** (`${n}`.length - 1));
            const maxValue = ceil(parseInt(dataAbsMax * 1.1));
            let positions = [0, maxValue / 2, maxValue];
            if (maxValue === 0) {
              positions = [0, 5, 10];
            }
            return positions;
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
          },
        },
        tooltip: {
          crosshairs: true,
          headerFormat: '',
          shared: true,
          borderColor: '#94A3B8',
          backgroundColor: '#FFFFFFE6',
          useHTML: true,
          formatter: function () {
            return this.points.reduce(function (s, p) {
              let tooltipShapeClass = p.point.period.match(/予想/)
                ? 'dividend-legend dividend-legend--base-expected'
                : 'dividend-legend dividend-legend--base';
              let tooltipShape = p.y === 0 ? '' : `<span class="${tooltipShapeClass}"></span> `;
              return (
                s +
                '<br/>' +
                `${tooltipShape}` +
                `配当額: ${p.y.toLocaleString('ja-JP', { minimumFractionDigits: 2 })}円`
              );
            }, '<span style="font-size:10px">' + this.points[0].point.period);
          },
        },
        series: [{ data: this.seriesData }],
      };
    },
    /**
     * 年間1株配当額の推移のミニチャート表示用のデータを返す
     * @return {Array}
     */
    seriesData() {
      return this.changesInDps['dps_array'].map((dps, idx) => ({
        y: Number(dps),
        period: this.tooltipPeriod(this.changesInDps['fiscal_periods'][idx], idx),
        color: this.chartColor(idx),
      }));
    },
    /**
     * x軸に表示するメモリの値を返す
     * @return {Array}
     */
    categoriesData() {
      return this.changesInDps['fiscal_periods'].map((period, idx) => this.tooltipPeriod(period, idx));
    },
  },
  methods: {
    /**
     * チャートの色を返す
     * @params {Number} idx
     * @return {String}
     */
    chartColor: function (idx) {
      return this.isProjection(idx) ? '#4294F3' : '#BECAD3';
    },
    /**
     * ツールチップに表示する年度情報を返す
     * @params {String} period
     * @params {Number} idx
     * @return {String}
     */
    tooltipPeriod: function (period, idx) {
      const array = period.split('.');
      if (this.isProjection(idx)) {
        return array[0] + '年 ' + array[1] + '月期 (予想)';
      } else {
        return array[0] + '年 ' + array[1] + '月期';
      }
    },
    /**
     * 予想データならtrueを返す
     * @params {Number} idx
     * @return {Boolean}
     */
    isProjection: function (idx) {
      return idx === this.changesInDps['dps_array'].length - 1;
    },
  },
};
</script>

import gql from 'graphql-tag';
import { FRAGMENT_FINANCIAL_ITEM as FRAGMENT_F11M } from './fragments/FinancialItemFragment';
import { FRAGMENT_CALENDAR_PERIOD } from './fragments/CalendarPeriodFragment';

const IPO_ITEM_TYPE_FOR_IPO_CALENDAR = `
  financialItemCalendars {
    financialItem {
      ...financialItem
    }
    financialItemCode
    ipoStockName
    provisionalConditionFilingDate
    bookBuildingPeriodFrom
    bookBuildingPeriodTo
    subscriptionPriceDeterminationDate
    subscriptionPeriodFrom
    subscriptionPeriodTo
    listingDate
    ipoCanceled
  }
  holidays
  calendarPeriod {
    ...calendarPeriod
  }
`;

export const QUERY_IPO_CALENDAR = gql`
  query ipoCalendar($year: Int!, $month: Int!) {
    ipoCalendar(year: $year, month: $month) {
      ${IPO_ITEM_TYPE_FOR_IPO_CALENDAR}
    }
  }
  ${FRAGMENT_F11M}
  ${FRAGMENT_CALENDAR_PERIOD}
`;

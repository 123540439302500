<template src="./NewsItems.html"></template>

<script>
import { ContentLoader } from 'vue-content-loader';
import TailwindPagination from '../../Pagination/TailwindPagination.vue';
import { QUERY_NEWS_ITEMS as NEWS_ITEMS } from '../../../queries/NewsItemsQuery';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

/**
 * ニュース一覧表示コンポーネント
 *
 * @vue-components {ContentLoader} contentLoader シャドースケルトン用
 * @vue-components {TailwindPagination} newsPagination ページネーション
 * @vue-apollo {Object} Items News情報
 * @module pages/News/NewsItems
 */
export default {
  name: 'NewsItems',
  components: {
    contentLoader: ContentLoader,
    newsPagination: TailwindPagination,
  },
  props: {
    /**
     * タグ
     * @vue-props {Array<String>}
     */
    tags: {
      type: Array,
      required: true,
      default: null,
    },
    /**
     * 取得件数
     * @vue-props {Number}
     */
    limit: {
      type: Number,
      required: false,
      default: 20,
    },
    /**
     * ページネーション表示フラグ
     * @vue-props {Boolean}
     */
    isPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Tailwind(V2ページ)か否か
     * @vue-props {Boolean}
     */
    isTailwind: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newsArticlesByTags: null,
    };
  },
  computed: {
    /**
     * 新着ニュースか否か
     * @return {Boolean}
     */
    isNew() {
      return (publishedAt) => {
        const date = dayjs(publishedAt);
        const now = dayjs();
        const twoHoursAgo = now.subtract(2, 'hour');
        return date.isSameOrAfter(twoHoursAgo, 'minute');
      };
    },
    /**
     * 本日のニュースか否か
     * @return {Boolean}
     */
    isToday() {
      return (publishedAt) => {
        const date = dayjs(publishedAt);
        const today = dayjs();
        return date.isSame(today, 'day');
      };
    },
    /**
     * 去年のニュースか否か
     * @return {Boolean}
     */
    isLastYear() {
      return (publishedAt) => {
        const currentYear = dayjs(publishedAt).year();
        const lastYear = dayjs().subtract(1, 'year').year();
        return currentYear === lastYear;
      };
    },
    /**
     * フォーマット済みのニュースの公開日付
     * @return {String}
     */
    formatedPublishedAt() {
      return (publishedAt) => {
        if (this.isLastYear(publishedAt)) {
          return this.formatDateToYYYYMMDD(publishedAt);
        } else if (this.isToday(publishedAt)) {
          return `今日 ${this.formatDateToHHmm(publishedAt)}`;
        } else {
          return this.formatDateToMMDDHHmm(publishedAt);
        }
      };
    },
    /**
     * 現在ページ番号
     * @return {Integer}
     */
    currentPage() {
      return this.$store.getters['tailwindPagination/currentPage'];
    },
    /**
     * 総ページ数
     * @return {Integer}
     */
    totalPage() {
      if (!this.newsArticlesByTags) {
        return null;
      }
      return this.newsArticlesByTags.pageInfo.totalPage;
    },
    /**
     * ニュース記事があるか否か
     * @return {Boolean}
     */
    hasNewsItemsCollection() {
      if (!this.newsArticlesByTags) {
        return false;
      }

      return this.newsArticlesByTags.collection.length > 0;
    },
    /**
     * デバイスの幅
     * @return {Integer}
     */
    getDeviseWidth() {
      return window.innerWidth;
    },
    /**
     * ページネーションを表示するか否か
     * @return {Boolean}
     */
    isShowPagination() {
      return !this.$apollo.loading && this.hasNewsItemsCollection && this.isPagination;
    },
  },
  apollo: {
    newsArticlesByTags: {
      query: NEWS_ITEMS,
      skip() {
        return true;
      },
      variables() {
        return {
          tags: this.tags,
          page: this.currentPage,
          limit: this.limit,
        };
      },
    },
  },
  mounted() {
    this.lazyQuery();
  },
  methods: {
    /**
     * @note mountedされるまでpropsから引数を受け取る事ができないので、mountedでskipをfalseにして、queryを発火させている
     */
    lazyQuery() {
      this.$apollo.queries.newsArticlesByTags.skip = false;
      this.$emit('data-loaded', false);
    },
  },
};
</script>

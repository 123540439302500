<template src="./AccordionRecommendedCriteria.html" />
<script>
/**
 * 銘柄検索 アコーディオンおすすめ条件コンポーネント
 * @module StockSearch/modules/accordion/AccordionRecommendedCriteria
 */
export default {
  name: 'RecommendedCriteriaAccordion',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    /**
     * 開閉
     */
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

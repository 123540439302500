<template src="./IPOCalendar.html" />

<script>
import { ContentLoader } from 'vue-content-loader';
import IPOCalendarMonthPicker from './IPOCalendarMonthPicker.vue';
import IPOCalendarBuilder from '../../../../models/IPOCalendars/IPOCalendarBuilder';
import FavoriteButtonHeart from '../../../Common/FavoriteButtonHeart.vue';
import { QUERY_IPO_CALENDAR as IPO_CALENDAR } from '../../../../queries/IPOCalendarQuery';

/**
 * IPOカレンダーコンポーネント
 * @vue-data {boolean} firstDisplay 初回表示したら真にする
 * @vue-components {ContentLoader} contentLoader
 * @vue-components {IPOCalendarMonthPicker} ipoCalendarMonthPicker IPOカレンダーMonthPicker
 * @vue-components {FavoriteButtonHeart} favoriteButtonHeart お気に入りボタン
 * @vue-apollo {Object} ipoCalendar IPOカレンダーAPIの返値
 * @vue
 * @module IPOCalendars/IPOCalendar
 */
export default {
  name: 'IPOCalendar',
  components: {
    contentLoader: ContentLoader,
    ipoCalendarMonthPicker: IPOCalendarMonthPicker,
    favoriteButtonHeart: FavoriteButtonHeart,
  },
  props: {
    /**
     * @vue-props {Number} 初期年
     */
    defaultYear: {
      type: Number,
      require: true,
      default: null,
    },
    /**
     * @vue-props {Number} 初期月
     */
    defaultMonth: {
      type: Number,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      firstDisplay: false,
      ipoCalendar: null, // @note テストでsetData()を使うため、queryを初期化している
    };
  },
  apollo: {
    ipoCalendar: {
      query: IPO_CALENDAR,
      skip() {
        return true;
      },
      variables() {
        return {
          year: this.calendarYear,
          month: this.calendarMonth,
        };
      },
    },
  },
  computed: {
    /**
     * カレンダーの年
     * @return {Number}
     */
    calendarYear() {
      return this.$store.getters['ipoCalendar/calendarYear'];
    },
    /**
     * カレンダーの月
     * @return {Number}
     */
    calendarMonth() {
      return this.$store.getters['ipoCalendar/calendarMonth'];
    },
    /**
     * MonthPickerが表示されていれば真
     * @return {boolean}
     */
    isVisibleMonthPicker() {
      return this.$store.getters['ipoCalendar/isVisibleMonthPicker'];
    },
    /**
     * PC版：当月でない場合に真、SP版：常に真
     * @return {boolean}
     */
    isNotCalendarMonth() {
      if (this.isSp) {
        return true;
      }
      return !(this.calendarYear === this.defaultYear && this.calendarMonth === this.defaultMonth);
    },
    /**
     * IPOカレンダー生成クラスのインスタンス
     * @return {IPOCalendarBuilder}
     */
    ipoCalendarBuilder() {
      return new IPOCalendarBuilder(this.calendarYear, this.calendarMonth);
    },
    /**
     * IPOカレンダーのヘッダー部オブジェクトの配列
     * @return {Array<Object>}
     */
    ipoCalendarHeaderCells() {
      if (this.ipoCalendar) {
        return this.ipoCalendarBuilder.buildHeader(this.ipoCalendar.holidays);
      } else {
        return [];
      }
    },
    /**
     * IPOカレンダーの金融アイテム行オブジェクトの配列
     * @return {Array<Object>}
     */
    ipoCalendarF11mRows() {
      let hasF11m;
      let f11mName;
      if (this.ipoCalendar) {
        return this.ipoCalendar.financialItemCalendars.map((f11mCalendar) => {
          hasF11m = !!f11mCalendar.financialItem;
          f11mName = hasF11m ? f11mCalendar.financialItem.nameAbbr : f11mCalendar.ipoStockName;
          return this.ipoCalendarBuilder.buildRow(f11mName, f11mCalendar.financialItemCode, hasF11m, f11mCalendar);
        });
      } else {
        return [];
      }
    },
    /**
     * IPOカレンダーの本日かどうかの配列
     * @return {Array<boolean>}
     */
    ipoCalendarTodayCells() {
      if (!this.ipoCalendar) {
        return [];
      }

      return this.ipoCalendarBuilder.buildToday();
    },
    /**
     * 初期年のIPO一覧ページのURL
     * @return {string}
     */
    ipoShowUrl() {
      return `/ipo/${this.defaultYear}`;
    },
    /**
     * 前の月にIPO情報があれば真
     * @return {boolean}
     */
    hasPrevMonth() {
      return this.$store.getters['ipoCalendar/hasPrevMonth'];
    },
    /**
     * 次の月にIPO情報があれば真
     * @return {boolean}
     */
    hasNextMonth() {
      return this.$store.getters['ipoCalendar/hasNextMonth'];
    },
    /**
     * デフォルト状態に戻るためのリンクのアンカーPC版：今月、SP版：今日
     * @return {string}
     */
    currentMonthOrDay() {
      return this.isSp ? '今日' : '今月';
    },
  },
  mounted() {
    this.initMonth();
    this.ipoCalendarLazyQuery();
  },
  beforeUpdate() {
    this.mutationCalendarPeriod();
  },
  updated() {
    this.adjustDisplayStartPosition();
  },
  methods: {
    /**
     * @note
     *   mountedされるまでpropsから引数を受け取る事ができないので、
     *   mountedでskipをfalseにして、queryを発火させている
     */
    ipoCalendarLazyQuery() {
      this.$apollo.queries.ipoCalendar.skip = false;
    },
    /**
     * ipoCalendarを取得できたら、Storeにカレンダーの期間をcommitする
     */
    mutationCalendarPeriod() {
      if (this.ipoCalendar) {
        this.$store.commit('ipoCalendar/calendarPeriod', this.ipoCalendar.calendarPeriod);
      }
    },
    /**
     * IPOカレンダーが描画されたら、表示開始位置を調整する
     */
    adjustDisplayStartPosition() {
      if (this.ipoCalendar && !this.firstDisplay) {
        this.$store.dispatch('ipoCalendar/stickyScroll');
        this.firstDisplay = true;
      }
    },
    /**
     * 前の月の情報を表示
     */
    prevMonth() {
      this.$store.dispatch('ipoCalendar/prevMonth');
      this.$store.dispatch('ipoCalendar/cancelStickyScroll');
    },
    /**
     * 次の月の情報を表示
     */
    nextMonth() {
      this.$store.dispatch('ipoCalendar/nextMonth');
      this.$store.dispatch('ipoCalendar/cancelStickyScroll');
    },
    /**
     * 初期表示月を表示して、当日にstickyScrollを合わせる
     */
    initMonth() {
      this.$store.commit('ipoCalendar/calendarYear', this.defaultYear);
      this.$store.commit('ipoCalendar/calendarMonth', this.defaultMonth);
      this.$store.dispatch('ipoCalendar/stickyScroll');
      this.firstDisplay = false;
    },
    toggleMonthPicker() {
      this.$store.dispatch('ipoCalendar/toggleMonthPicker');
    },
    /**
     * MonthPickerを非表示にする
     */
    hideMonthPicker() {
      this.$store.dispatch('ipoCalendar/hideMonthPicker');
      this.$store.commit('ipoCalendar/pickerYear', null);
    },
    /**
     * モーダルを表示する
     */
    showModal() {
      this.$store.commit('favoriteSignUpModal/show');
    },
  },
};
</script>

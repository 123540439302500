<template src="./DividendYieldChart.html"></template>
<script>
import VariantUtil from '../../../utils/VariantUtil';
import { Chart } from 'highcharts-vue';

/*
 * 個別銘柄配当ページの配当利回り推移チャートコンポーネント
 *
 * @vue-prop {Hash} chartElements
 * @module DividendYieldChart
 */
export default {
  name: 'DividendYieldChart',
  components: {
    highcharts: Chart,
  },
  props: {
    chartElements: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: this.readHeight,
          style: {
            fontFamily:
              "-apple-system,'BlinkMacSystemFont','Hiragino Kaku Gothic ProN','Noto Sans CJK JP','Noto Sans Japanese',sans-serif",
          },
        },
        title: {
          text: '',
        },
        lang: {
          decimalPoint: '.',
          thousandsSeparator: ',',
        },
        xAxis: {
          title: {
            text: '',
          },
          offset: 1,
          lineWidth: 2,
          lineColor: '#808080',
          categories: this.chartElements['fiscal_periods'].map((period, idx) =>
            idx === this.chartElements['fiscal_periods'].length - 1 ? `${period}<br>(予想)` : period
          ),
          softMin: 0,
          labels: {
            autoRotationLimit: 40,
            style: {
              fontSize: 12,
            },
          },
          crosshair: false,
        },
        yAxis: [
          {
            title: {
              text: '（%）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: -42,
              style: {
                fontSize: 12,
              },
            },
            softMin: 0,
            labels: {
              style: {
                fontSize: 12,
              },
              format: '{value:,.1f}',
            },
            min: 0,
          },
          {
            title: {
              text: '（円）',
              rotation: 0,
              align: 'low',
              offset: 0,
              y: 20,
              x: 42,
              style: {
                fontSize: 12,
              },
            },
            opposite: true,
            softMin: 0,
            labels: {
              style: {
                fontSize: 12,
              },
              format: '{value:,.0f}',
            },
          },
        ],
        series: [
          {
            type: 'line',
            name: '配当利回り',
            color: '#F7931D',
            data: this.formatDividendYields,
            zoneAxis: 'x',
            zones: [{ value: 3 }, { dashStyle: 'ShortDash' }],
            zIndex: 2,
            yAxis: 0,
          },
          {
            type: 'line',
            name: '平均株価',
            color: '#2A6495',
            data: this.formatAveragePrices,
            marker: {
              radius: 5,
              symbol: 'square',
            },
            zIndex: 1,
            yAxis: 1,
          },
          {
            type: 'line',
            name: '現在値',
            color: '#2A6495',
            data: this.formatLatestTrade,
            marker: {
              fillColor: '#FFFFFF',
              radius: 5,
              symbol: 'square',
              lineWidth: 2,
              lineColor: '#2A6495',
            },
            zIndex: 1,
            yAxis: 1,
          },
        ],
        plotOptions: {
          series: {
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        tooltip: {
          shared: true,
          borderColor: '#94A3B8',
          backgroundColor: '#FFFFFFE6',
          useHTML: true,
          formatter: function () {
            const [year, month] = this.x.replace('<br>', '').split('.');
            const month_num = month.replace(/[^0-9]/g, '');
            const projection = month.replace(/[0-9]/g, '');
            const fiscal_period = this.points[0].name === '現在値' ? '' : `${year}年${month_num}月期${projection}`;
            return this.points.reduce(function (s, p) {
              let tooltipShape = '';
              if (p.key === '配当利回り') {
                tooltipShape = p.x.match(/予想/)
                  ? 'dividend-legend dividend-legend--yield-expected'
                  : 'dividend-legend dividend-legend--yield';
              } else if (p.key === '平均株価') {
                tooltipShape = 'dividend-legend dividend-legend--average';
              } else if (p.key === '現在値') {
                tooltipShape = 'dividend-legend dividend-legend--current';
              }
              return (
                s +
                '<br>' +
                `<span class="${tooltipShape}"></span> ` +
                `${p.key}:${p.y.toLocaleString('ja-JP', { minimumFractionDigits: p.point.precision })}${p.point.unit}`
              );
            }, '<span style="font-size:12px">' + fiscal_period);
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
      };
    },
    formatDividendYields() {
      return this.chartElements['dividend_yields'].map((value, idx) => ({
        y: value == undefined ? null : Math.floor(Number(value) * 100) / 100,
        name: '配当利回り',
        precision: 2,
        unit: '%',
        color: '#F7931D',
        marker:
          idx === this.chartElements['fiscal_periods'].length - 1
            ? { radius: 5, lineColor: '#F7931D', lineWidth: 2, fillColor: '#FFFFFF' }
            : { radius: 5 },
      }));
    },
    formatAveragePrices() {
      return this.chartElements['average_prices'].map((value) => ({
        y: value == undefined ? null : Math.floor(Number(value) * 10) / 10,
        name: '平均株価',
        unit: '円',
        precision: 1,
      }));
    },
    formatLatestTrade() {
      const nullSize = this.chartElements['fiscal_periods'].length - 1;
      const nullArray = new Array(nullSize).fill(null);
      return nullArray.concat([
        {
          y:
            this.chartElements['latest_trade'] == undefined
              ? null
              : Math.floor(Number(this.chartElements['latest_trade']) * 10) / 10,
          name: '現在値',
          unit: '円',
          precision: 1,
        },
      ]);
    },
    readHeight() {
      return VariantUtil.isSp() ? 220 : 365;
    },
  },
};
</script>

import StockSearchWrapper from './StockSearch.vue';
import SearchCriteria from './SearchCriteria.vue';
import SearchResult from './SearchResult.vue';
import StockSearchSlider from './modules/slider/StockSearchSlider.vue';
import StockSearchChkbox from './modules/chkbox/StockSearchChkbox.vue';
import SearchCustomize from './modules/customize/SearchCustomize.vue';
import GlossaryTooltip from './modules/tooltip/GlossaryTooltip.vue';
import PremiumTooltip from './modules/tooltip/PremiumTooltip.vue';
import PremiumModal from './modules/modal/PremiumModal.vue';
import SignUpModal from './modules/modal/SignUpModal.vue';
import CriteriaModal from './modules/modal/CriteriaModal.vue';
import StockSearchPagination from './modules/pagination/StockSearchPagination.vue';
import StockSearchPaginationText from './modules/pagination/StockSearchPaginationText.vue';
import AccordionSection from './modules/accordion/AccordionSection.vue';
import SectionDescription from './modules/description/SectionDescription.vue';
import AccordionRecommendedCriteria from './modules/accordion/AccordionRecommendedCriteria.vue';
import StockSummaryModal from './modules/stockSummaryModal/StockSummaryModal.vue';
import SaveConditions from './modules/save/SaveConditions.vue';
import SaveModal from './modules/save/SaveModal.vue';
import CampaignBanner from '../../modules/Banner/CampaignBanner.vue';

/**
 * 銘柄検索ルートコンポーネント
 *
 * @vue-components {StockSearch} stockSearch 銘柄検索
 * @vue-components {StockSearchSlider} StockSearchSlider 検索条件スライダー
 * @vue-components {StockSearchChkbox} StockSearchChkbox 検索条件チェックボックス
 * @module StockSearch
 */

const StockSearch = {
  el: '#v-stock-search',
  components: {
    StockSearch: StockSearchWrapper,
    SearchCriteria,
    SearchResult,
    StockSearchSlider,
    StockSearchChkbox,
    SearchCustomize,
    PremiumModal,
    GlossaryTooltip,
    PremiumTooltip,
    SectionDescription,
    SignUpModal,
    CriteriaModal,
    StockSearchPagination,
    StockSearchPaginationText,
    AccordionSection,
    AccordionRecommendedCriteria,
    StockSummaryModal,
    SaveConditions,
    SaveModal,
    CampaignBanner,
  },
};
export default StockSearch;
